import clsx from 'clsx'
import { useTranslation } from 'next-i18next'
import { FC, useEffect, useState } from 'react'

import BenefitsCard from '@/components/BenefitsCard'
import Button from '@/components/Button'
import Heading from '@/components/Heading'
import Icon from '@/components/Icon'
import Link from '@/components/Link'

import { TypeContentProp, TypeProp } from './Possibilities.interface'
import styles from './Possibilities.module.scss'
import { useUser } from '@/bus/user'
import { routes } from '@/config'

const Type: FC<TypeProp> = ({ className = '', id = 0 }) => {
  const { t } = useTranslation()
  const [animationStart, setAnimationStart] = useState(false)

  const {
    bg = '#fff',
    heading = null,
    summary = null,
    cards = []
  } = t(`features:tabs.${id}.content`, {
    returnObjects: true
  }) as TypeContentProp

  useEffect(() => {
    setAnimationStart(true)
    return () => {
      setAnimationStart(false)
    }
  }, [])

  let { isAuthorized } = useUser()

  isAuthorized = true

  const ButtonsComponent = ({ type }: { type: string }) => {
    switch (type) {
      case 'white-label': {
        return (
          <>
            <Button
              title={t('forms:buttons.documentation') as string}
              as={Link}
              href={routes.DOC}
              size={'l'}
              className={styles['possibilities__button']}
              before={<Icon name={'arrows/right/24'} />}
            >
              <span
                dangerouslySetInnerHTML={{
                  __html: t('forms:buttons.documentation')
                }}
              />
            </Button>
            <Button
              title={t('forms:buttons.consultation') as string}
              as={Link}
              href={{ pathname: routes.SUPPORT, external: true }}
              size={'l'}
              className={styles['possibilities__button']}
              before={<Icon name={'user/24'} />}
            >
              <span
                dangerouslySetInnerHTML={{
                  __html: t('forms:buttons.consultation')
                }}
              />
            </Button>
          </>
        )
      }
      case 'promised-payment': {
        return (
          <>
            <Button
              title={t('forms:buttons.signup') as string}
              as={Link}
              href={
                isAuthorized
                  ? {
                      pathname: routes.SERVICES,
                      external: false
                    }
                  : routes.REGISTER
              }
              size={'l'}
              className={styles['possibilities__button']}
              before={<Icon name={'arrows/right/24'} />}
            >
              {t(
                isAuthorized ? 'forms:buttons.services' : 'forms:buttons.signup'
              )}
            </Button>

            <Button
              variant={'bordered'}
              title={t('forms:buttons.consultation') as string}
              as={Link}
              href={routes.PROMISED_PAYMENT_TERMS}
              size={'l'}
              className={styles['possibilities__button']}
            >
              <span
                dangerouslySetInnerHTML={{
                  __html: t('forms:buttons.more')
                }}
              />
            </Button>
          </>
        )
      }

      default: {
        return (
          <Button
            title={t('forms:buttons.signup') as string}
            as={Link}
            href={
              isAuthorized
                ? {
                    pathname: routes.SERVICES,
                    external: false
                  }
                : routes.REGISTER
            }
            size={'l'}
            className={styles['possibilities__button']}
            before={<Icon name={'arrows/right/24'} />}
          >
            {t(
              isAuthorized ? 'forms:buttons.services' : 'forms:buttons.signup'
            )}
          </Button>
        )
      }
    }
  }

  return (
    <div
      className={clsx(
        styles['possibilities'],
        { 'animation-start': animationStart },
        className,
        styles[`possibilities_type-${id}`]
      )}
      style={{ backgroundColor: bg }}
    >
      <div className={styles['possibilities__column']}>
        <div className={styles['possibilities__content']}>
          <Heading className={styles['possibilities__heading']}>
            {heading}
          </Heading>

          {summary && (
            <div
              className={styles['possibilities__summary']}
              dangerouslySetInnerHTML={{ __html: summary }}
            />
          )}
          <div className={styles['possibilities__links']}>
            <ButtonsComponent type={id as string} />
          </div>
        </div>
      </div>
      <div className={styles['possibilities__column']}>
        <ul className={styles['possibilities__list']}>
          {cards.map(({ id, heading, summary }, index) => (
            <li key={id} className={clsx(styles['possibilities__item'])}>
              <BenefitsCard
                id={id}
                heading={heading}
                summary={summary}
                index={index}
              />
            </li>
          ))}
        </ul>
        <div className={styles['possibilities__links']}>
          <ButtonsComponent type={id as string} />
        </div>
      </div>
    </div>
  )
}

export default Type
