import clsx from 'clsx'
import { useTranslation } from 'next-i18next'
import { FC, useEffect, useRef, useState } from 'react'
import { useIntersection } from 'react-use'

import Heading from '@/components/Heading'
import PossibilitiesCards from '@/components/PossibilitiesCards'
import Tabs from '@/components/Tabs'

import DefaultComponentProps from '@/types/DefaultComponentProps'

import styles from './Section.module.scss'
import { useMenu } from '@/bus/menu'

export const Features: FC<DefaultComponentProps> = ({ className = '' }) => {
  const { t } = useTranslation()
  const TABS = t('features:tabs', { returnObjects: true }) as {
    [key: string]: { id: string; title: string }
  }

  const [activeId, setActiveId] = useState(TABS['platform'].id)
  const { setData } = useMenu()
  const handleClick = (id: string) => {
    setActiveId(id)
  }

  const intersectionRef = useRef(null)

  const intersection = useIntersection(intersectionRef, {
    root: null,
    rootMargin: '-116px',
    threshold: 0.1
  })

  useEffect(() => {
    setData({
      interSection: intersection?.isIntersecting ? 'opportunities' : null
    })

    // eslint-disable-next-line
  }, [intersection?.isIntersecting])

  return (
    <section
      id={'opportunities'}
      ref={intersectionRef}
      className={clsx(styles['section'], className)}
    >
      <div className={styles['section__content']}>
        <Heading className={styles['section__heading']} level={2}>
          {t('features:heading')}
        </Heading>
        <Tabs
          className={styles['section__tabs']}
          items={Object.values(TABS).map(({ title, id }) => ({
            id,
            active: id === activeId,
            name: title
          }))}
          onClick={handleClick}
        />
        <PossibilitiesCards
          className={styles['section__possibilities']}
          type={activeId}
        />
      </div>
    </section>
  )
}
