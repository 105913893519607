import {
  Options,
  Splide,
  SplideSlide,
  SplideTrack
} from '@splidejs/react-splide'
import { Grid } from '@splidejs/splide-extension-grid'
import clsx from 'clsx'
import { FC, useEffect, useState } from 'react'
import { Parallax } from 'react-scroll-parallax'
import { Waypoint } from 'react-waypoint'

import Icon from '@/components/Icon'

import events from '@/utils/events'

import DefaultComponentProps from '@/types/DefaultComponentProps'

import styles from './Cards.module.scss'

interface CardProps {
  image: string
  title: string
  id: string
}

interface CardsProps extends DefaultComponentProps {
  items: CardProps[]
  name: string
}

const OPTIONS: Options = {
  rewind: true,
  gap: '1.4rem',
  autoHeight: true,
  arrows: false,
  classes: {
    pagination: `splide__pagination ${styles['cards__splide-pagination']}`,
    page: `splide__pagination__page  ${styles['cards__splide-page']}`
  },
  mediaQuery: 'min',
  autoWidth: true,
  padding: {
    left: '3%',
    right: '3%'
  },
  breakpoints: {
    320: {
      autoWidth: false,
      gap: '9rem',
      fixedWidth: 'calc(100vw - 9rem)',
      perMove: 1,
      perPage: 1,
      grid: {
        cols: 2,
        rows: 2,
        gap: {
          row: '1.3rem',
          col: '1.4rem'
        }
      },
      padding: { right: '4.5rem', left: '4.5rem' }
    },

    640: {
      gap: '1.4rem',
      fixedWidth: 138,
      grid: false,
      padding: { right: '2.6rem', left: '2.6rem' },
      pagination: false,
      perMove: 1,
      perPage: 1
    },
    960: {
      fixedWidth: 168,
      gap: '1.7rem'
    },

    1024: {
      fixedWidth: 179,
      gap: '2rem',
      padding: { right: '2.8rem', left: '2.8rem' }
    },

    1366: {
      fixedWidth: 200
    },

    1680: {
      focus: 'center'
    }
  }
}

const Cards: FC<CardsProps> = ({ className = '', items = [], name }) => {
  const [animationStart, setAnimationStart] = useState(false)

  useEffect(() => {
    events.on('waypoint::5::enter', () => setAnimationStart(true))
    events.on('waypoint::5::leave', () => setAnimationStart(false))

    return () => {
      events.off('waypoint::5::enter', () => setAnimationStart(true))
      events.off('waypoint::5::leave', () => setAnimationStart(false))
    }
  }, [])

  return (
    <Waypoint
      onEnter={({ previousPosition }) => {
        if (previousPosition === 'below' || !previousPosition) {
          events.emit('waypoint::5::enter')
        }
      }}
      onLeave={({ currentPosition }) => {
        if (currentPosition === 'below') {
          events.emit('waypoint::5::leave')
        }
      }}
    >
      <div
        className={clsx(styles['cards'], className, {
          [styles['cards_animation-start']]: animationStart
        })}
      >
        <Parallax speed={-10} translateX={[3, -3]} translateY={[0, 0]}>
          <Splide
            className={styles['cards__splide']}
            hasTrack={false}
            options={OPTIONS}
            extensions={{ Grid }}
          >
            <SplideTrack className={styles['cards__track']}>
              {items.map(({ id, image, title }) => (
                <SplideSlide key={id} className={styles['cards__item']}>
                  <div className={styles['cards__card']}>
                    <div className={styles['cards__fig']}>
                      <Icon
                        className={styles['cards__fig-img']}
                        name={`methods/${image}`}
                      />
                    </div>
                    <div className={styles['cards__name']}>{title}</div>
                  </div>
                </SplideSlide>
              ))}
            </SplideTrack>
          </Splide>
        </Parallax>
      </div>
    </Waypoint>
  )
}
export default Cards
